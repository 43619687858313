<template>
    <div class="bottom-button">
        <div class="left-over">
            <span v-html="progressStr" />
        </div>
        <button class="btn" :class="progress.leftOver ? 'btn-black' : 'btn-primary'" @click="onClickBtn">
            {{ progress.leftOver ? '다음' : '작성완료' }}
        </button>
    </div>
</template>

<script>
export default {
    name: 'ContractProgressBtn',
    data: () => ({
        nowScroll: 0,
    }),
    props: {
        signStatus: Object,
    },
    computed: {
        progress() {
            return {
                needSign: this.signStatus.clicked.length,
                leftOver: this.signStatus.clicked.filter(c => c === false).length,
            }
        },
        progressStr() {
            const { needSign, leftOver } = this.progress
            return leftOver
                ? `입력할 곳이 <span class='c-primary'>${leftOver}곳</span> 남았습니다(${
                      needSign - leftOver
                  }/${needSign})`
                : `입력이 모두 완료되었습니다.(${needSign - leftOver}/${needSign})`
        },
    },
    methods: {
        onClickBtn() {
            if (this.progress.leftOver === 0) {
                this.$emit('submitContract')
                return
            }
            this.nowScroll = this.signStatus.clicked.findIndex(e => e === false)
            const sign = document.getElementById(`${this.signStatus.ids[this.nowScroll]}`)
            sign.scrollIntoView({ block: 'center', behavior: 'smooth' })
            this.$toast.success(`${this.nowScroll + 1}번째 서명을 진행해주세요`)
        },
    },
}
</script>

<style scoped lang="scss">
.bottom-button {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: white;
    padding: 16px 20px 16px 20px;
    z-index: 3;
    border-top: 1px solid $grey-02;
    @include between;

    .left-over {
    }

    button {
        height: 40px;

        &.disabled {
            background: $grey-02 !important;
        }
        &.blackBtn {
            background-color: black;
        }
    }
}
</style>
