export const v4Prepaid = {
    contract: {
        providerInfo: {
            service: '굿퍼슨',
            company: '주식회사바닐라브릿지',
            address: '서울시 강남구 테헤란로 19길 14, 401호 (역삼동)',
            businessNum: '서울-강남-국내-20-0012호',
            ceo: '최성만',
        },
        contractInfo: [
            {
                id: 1,
                content:
                    '회원가입 프로그램명은 <span class="spoqa-f-bold">%a</span>이며, 가입비는 <span class="spoqa-f-bold">%b원 </span>(VAT별도) 입니다.',
            },
            { id: 2, content: '성혼사례비는 없습니다.' },
            {
                id: 3,
                content:
                    '을은 갑에게 만남서비스이용기간 동안 <span class="spoqa-f-bold">%c회</span>의 만남 서비스를 제공합니다.',
            },
            {
                id: 4,
                content:
                    '만남서비스이용기간은 갑이 매니저로부터 처음으로 이성의 프로필을 받은 날부터 <span class="spoqa-f-bold">%d일</span>입니다. 만남서비스이용기간 안에 사용하지 않은 만남서비스 횟수는 환불되거나, 매니저와 합의 하에 만남서비스이용기간을 연장하여 사용할 수 있습니다.',
            },
            {
                id: 5,
                content:
                    '가입 시에 제공된 만남 서비스를 모두 사용하거나 만남서비스이용기간이 종료된 경우, 후불횟수제,선불횟수제,기간제 매칭 프로그램 중 하나를 추가로 구매하여 만남서비스를 이용 가능하며, 본 계약서는 효력을 지속합니다.',
            },
            {
                id: 6,
                content:
                    '갑이 추가적으로 매칭 프로그램을 구매하는 경우 을은 갑에게 회원 전용 앱(굿퍼슨)을 통해 만남서비스 및 매칭프로그램과 관련된 내용(프로그램의 가격, 이용 기간, 만남서비스 이용기간 및 만남횟수)을 안내하고 갑의 동의를 받습니다. 추가 구매한 프로그램의 계약해제 및 해제 시 환급 기준은 본 계약서의 내용을 따릅니다.',
            },
            {
                id: 7,
                content: '계약 해지 시  『소비자분쟁해결기준』(결혼중개업,공정거래위원회고시)에 따라 환급합니다.',
            },
            { id: 8, content: '기타 본 계약에서 정하지 않은 사항은 『굿퍼슨 이용 약관』에 의합니다.' },
            { id: 9, content: '을은 갑에게 본 계약서의 내용을 이해하기 쉽게 설명하고, 사본을 교부합니다.' },
        ],
        refundInfo: [
            {
                title: '*사업자(을)의 책임있는 사유로 계약해제 및 해지시 환급기준',
                content: [
                    {
                        id: 1,
                        case: '회원가입 계약성립 후 프로필(이성 회원의 정보) 제공 전에 계약이 해지되는 경우',
                        spec: [
                            { type: 'a.', content: '선불횟수제: 가입비 + 가입비의 10%' },
                            { type: 'b.', content: '후불횟수제: 제 13조의 환급 규정에 따름' },
                            { type: 'c.', content: '기간제: 가입비 + 가입비의 10%' },
                        ],
                    },
                    {
                        id: 2,
                        case: '회원가입 계약성립 후 프로필(이성 회원의 정보) 제공 후 만남 일자 확정 전에 해지되는 경우',
                        spec: [
                            { type: 'a.', content: '선불횟수제: 가입비 + 가입비의 15%' },
                            { type: 'b.', content: '후불횟수제: 제 13조의 환급 규정에 따름' },
                            { type: 'c.', content: '기간제: 가입비 + 가입비의 15%' },
                        ],
                    },
                    {
                        id: 3,
                        case: '회원가입 계약성립 후 회사가 주선한 만남 일자 확정 이후에 해지된 경우',
                        spec: [
                            { type: 'a.', content: '선불횟수제: 가입비 + 가입비의 20%' },
                            { type: 'b.', content: '후불횟수제: 제 13조의 환급 규정에 따름' },
                            { type: 'c.', content: '기간제: 가입비 + 가입비의 20%' },
                        ],
                    },
                    {
                        id: 4,
                        case: '1회 만남 이후 해지된 경우',
                        spec: [
                            { type: 'a.', content: '선불횟수제: 가입비×(잔여횟수/총횟수) + 가입비의 20%' },
                            { type: 'b.', content: '후불횟수제: 제 13조의 환급 규정에 따름' },
                            { type: 'c.', content: '기간제: 가입비×(잔여일수/총일수) + 가입비의 20%' },
                        ],
                    },
                ],
            },
            {
                title: '*사업자(을)의 책임없는 사유로 계약해제 및 해지시 환급기준',
                content: [
                    {
                        id: 1,
                        case: '회원가입 계약성립 후 프로필(이성 회원의 정보) 제공 전에 계약이 해지되는 경우',
                        spec: [
                            { type: 'a.', content: '선불횟수제: 가입비의 90%' },
                            { type: 'b.', content: '후불횟수제: 제 13조의 환급 규정에 따름' },
                            { type: 'c.', content: '기간제: 가입비의 90%' },
                        ],
                    },
                    {
                        id: 2,
                        case: '회원가입 계약성립 후 프로필(이성 회원의 정보) 제공 후 만남 일자 확정 전에 해지되는 경우',
                        spec: [
                            { type: 'a.', content: '선불횟수제: 가입비의 85%' },
                            { type: 'b.', content: '후불횟수제: 제 13조의 환급 규정에 따름' },
                            { type: 'c.', content: '기간제: 가입비의 85%' },
                        ],
                    },
                    {
                        id: 3,
                        case: '회원가입 계약성립 후 회사가 주선한 만남 일자 확정 이후에 해지된 경우',
                        spec: [
                            { type: 'a.', content: '선불횟수제: 가입비의 80%' },
                            { type: 'b.', content: '후불횟수제: 제 13조의 환급 규정에 따름' },
                            { type: 'c.', content: '기간제: 가입비의 80%' },
                        ],
                    },
                    {
                        id: 4,
                        case: '1회 만남 이후 해지된 경우',
                        spec: [
                            { type: 'a.', content: '선불횟수제: 가입비의 80% × (잔여횟수/총횟수)' },
                            { type: 'b.', content: '후불횟수제: 제 13조의 환급 규정에 따름' },
                            { type: 'c.', content: '기간제: 가입비의 80% × (잔여일수/총일수)' },
                        ],
                    },
                ],
            },
        ],
    },
    terms: [
        {
            title: '제1조(목적)',
            content:
                "이 약관 및 결혼중개계약서(붙임)는 주식회사 바닐라브릿지(국내결혼중개업자, 이하 “회사”라 함)가 제공하는 국내결혼 관련 중개서비스 ‘굿퍼슨' (이하 “서비스”라 함)를 이용함에 있어 회사와 회원 사이의 권리․의무 및 책임사항을 규정함을 목적으로 합니다.",
        },
        {
            title: '제2조 (정의)',
            content: [
                {
                    id: 1,
                    content:
                        '“회원”이라 함은 제3조에서 정한 가입절차에 따라 회사가 그 가입신청을 승낙하고 이 약관에 따라 계약을 체결한 사람을 말합니다.',
                },
                {
                    id: 2,
                    content: '“소개”라 함은 회사가 회원간에 결혼상대방을 구하기 위한 만남을 주선하는 것을 말합니다.',
                },
                {
                    id: 3,
                    content:
                        '매칭 프로그램"이라 함은 서로 소개받은 회원 두 명이 만남의 자리를 회사가 주선하는 서비스 방식과 과금 방식을 의미합니다.',
                },
                {
                    id: 4,
                    content:
                        '“만남 서비스 이용비(가입비)”는 회원이 매칭프로그램을 이용하기 위해 회사에 지급하는 비용을 의미합니다.',
                },
                {
                    id: 5,
                    content:
                        '“만남권"은 후불횟수제를 사용하는 회원이 1회 만남서비스를 이용하기 위해 사용하는 서비스내 재화이며 회사에 비용을 지급하고 구매합니다.',
                },
                {
                    id: 6,
                    content:
                        '“만남서비스이용기간”는 회원이 만남 서비스 이용이 가능한 기간을 의미하며 회원은 계약서에 정의된 기간 내에 만남서비스를 모두 이용하여야 합니다. ',
                },
                {
                    id: 7,
                    content: '“만남"은 오프라인 상에서 소개받은 회원들이 실제 대면 만남을 갖는 것을 의미합니다. ',
                },
                { id: 8, content: '“교제”라 함은 사회 통념상의 연인 관계가 개시되어 유지되고 있는 상태를 말합니다.' },
                { id: 9, content: '“성혼”이라 함은 법적 사실혼 상태를 말합니다.' },
                {
                    id: 10,
                    content:
                        '“결혼관련 개인정보”라 함은 학력, 직업, 병력 등 통상 결혼함에 있어 당사자 사이에 확인할 필요가 있는 것으로 인정되는 개인정보를 말합니다.',
                },
            ],
        },
        {
            title: '제3조 (회원가입)',
            content: [
                {
                    id: 1,
                    content:
                        '회원이 되려고 하는 사람은 결혼관련 개인정보를 회사에 제공한 후 회사가 정한 가입절차에 따라 회원가입을 신청합니다.',
                },
                {
                    id: 2,
                    content:
                        '회사는 제1항과 같이 회원가입을 신청한 자 중 다음 각 호의 사항에 대한 심사를 실시하여 그 적격여부를 판단합니다.',
                    specInfos: [
                        { id: 'a', content: '배우자(사실혼관계의 배우자 포함) 있는 자인지 여부의 확인' },
                        { id: 'b', content: '결혼관련 개인정보의 사실여부의 확인' },
                        { id: 'c', content: '회사가 정한 회원 가입 자격 적격 여부의 확인' },
                    ],
                },
                {
                    id: 3,
                    content:
                        '회사는 제2항의 심사결과 적격자로 인정한 신청자에 한하여 회원가입을 승낙하고, 이 약관에 따라 계약을 체결합니다.',
                },
            ],
        },
        {
            title: '제4조 (계약서․약관의 명시‧교부 등)',
            content: [
                {
                    id: 1,
                    content:
                        '회사는 계약 체결시 이 약관의 내용을 회원으로 가입하려는 자에게 설명하고, 계약서와 함께 이 약관을 교부해야 합니다.',
                },
                {
                    id: 2,
                    content:
                        '회사는 약관의 규제에 관한 법률, 소비자기본법 등 관련법령을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.',
                },
                {
                    id: 3,
                    content:
                        '회사가 이 약관을 개정할 경우에는 적용일자, 개정사유 및 개정내용을 명시하여 현행약관과 함께 그 적용일자 30일 이전까지 제15조의 방법으로 예고합니다.',
                },
                {
                    id: 4,
                    content:
                        '회사가 약관을 개정할 경우에는 그 개정약관은 그 적용일자 이후에 체결되는 계약에만 적용되고 그 이전에 이미 체결된 계약에 대해서는 개정전의 약관조항이 그대로 적용됩니다. 다만, 이미 계약을 체결한 회원이 개정약관 조항의 적용을 받기를 원하는 뜻을 제3항에 의한 개정약관의 예고기간 내에 회사에 서면으로 통지하여 회사의 동의를 받은 경우에는 개정약관조항이 적용됩니다.',
                },
            ],
        },
        {
            title: '제5조 (서비스의 제공)',
            content: [
                {
                    id: 1,
                    content: '회사는 회원에게 다음과 같은 서비스를 제공합니다.',
                    specInfos: [
                        { id: 'a', content: '회원에 대한 결혼상담 및 인터넷 등을 통한 결혼관련 정보의 제공' },
                        { id: 'b', content: '이성 회원의 소개' },
                        { id: 'c', content: '이성 회원과의 일대일 만남 주선 및 행사 등의 개최' },
                        { id: 'd', content: '회원에 대한 결혼관련 개인정보의 관리' },
                        { id: 'e', content: '기타 결혼과 관련된 사항으로서 회사가 정하는 서비스' },
                    ],
                },
                {
                    id: 2,
                    content:
                        '회사는 서비스의 내용과 방법이 변경된 경우에는 회원의 동의가 없는 한 이 약관 변경의 방법에 의해서만 서비스의 내용과 방법을 변경할 수 있습니다.',
                },
            ],
        },
        {
            title: '제6조 (회원자격의 보유기간)',
            content: [
                {
                    id: 1,
                    content:
                        '회원이 회사로부터 제5조에서 정한 서비스를 제공받을 수 있는 기간은 다음과 같습니다. 단, 회사의 재량으로 회원자격의 보유기간을 연장할 수 있고, 이 사실을 회원에게 고지합니다.',
                    specInfos: [
                        { id: 'A', content: '선불횟수제: 계약서 제 4조에서 정한 만남서비스이용기간과 같음' },
                        { id: 'B', content: '후불횟수제: 계약서 제 4조에서 정한 만남서비스이용기간과 같음' },
                        { id: 'C', content: '기간제: 계약서 제 3조에서 정한 만남서비스이용기간과 같음' },
                    ],
                },
                {
                    id: 2,
                    content:
                        '회사의 책임있는 사유로 회원에게 서비스를 제공하지 못하고 제1항 본문의 기간이 경과한 경우, 회원은 회사에 대하여 당해 기간만큼 회원자격 보유기간을 연장하여 줄 것을 요청하거나 계약을 해지할 수 있습니다.',
                },
            ],
        },
        // {
        //     title: '제7조 (휴면 기간)',
        //     content: [
        //         {
        //             id: 1,
        //             content:
        //                 '회원은 가입 기간 중에 회사에 대하여 휴면을 요청할 수 있습니다. 휴면 처리가 되면, 회원의 프로필을 타 회원에게 제공하지 않으며, 제5조의 서비스 제공을 일시 정지합니다.',
        //         },
        //         {
        //             id: 2,
        //             content:
        //                 '휴면 신청 방법, 신청 가능 횟수, 최대 휴면 기간은 매칭 프로그램에 따라 상이하며 자세한 규정은 매니저와 멤버전용앱을 통해 안내합니다.',
        //         },
        //     ],
        // },
        {
            title: '제7조 (회원의 권리)',
            content: [
                { id: 1, content: '회사는 회원에게 이성을 소개하는 경우 만남에 필요한 제반정보를 사전에 제공합니다. ' },
                {
                    id: 2,
                    content:
                        '회원은 회사의 이성 소개에 대하여 만남을 보류하거나 취소할 것을 신청할 수 있습니다. 이때 회원은 이미 다른 회원과 교제 중이거나 본인의 입원, 출장 또는 가족의 사고나 사망 등 상대방과의 만남에 응할 수 없는 불가피한 사정을 소명함으로써 만남서비스이용비를 환불받을 수 있습니다.',
                },
                {
                    id: 3,
                    content:
                        '회원은 회사로부터 고유번호를 부여받아 회사가 제공하는 인터넷정보서비스를 유료 또는 무료로 이용할 수 있습니다.',
                },
                { id: 4, content: '회원은 회사가 주최하는 각종 행사에 유료 또는 무료로 참가할 수 있습니다.' },
            ],
        },
        {
            title: '제8조 (회원의 의무)',
            content: [
                {
                    id: 1,
                    content:
                        '회원은 회사에 결혼관련 개인정보를 사실대로 제공하여야 합니다. 만일 허위로 개인정보를 제공하여 이로 인해 회사와 타 회원에게 피해가 발생할 경우 회사는 해당 회원에 대하여 민형사상의 법적 조치를 취할 수 있습니다.',
                },
                {
                    id: 2,
                    content:
                        '회원이 가입 기간 동안 서비스를 제공받기 위하여서는 법적으로 미혼이어야 하고, 이성과 교제하는 상태에 있어서는 아니됩니다.',
                },
                {
                    id: 3,
                    content:
                        '회원은 회사에 제공한 결혼관련 개인정보에 변동이 있을 경우 15일 이내에 회사에 이를 통지하여야 합니다.',
                },
                { id: 4, content: '회원은 회사와 합의하여 회사가 소개한 상대방과의 만남에 응하여야 합니다.' },
                {
                    id: 5,
                    content:
                        '회원이 가입 기간 내에 이성과 교제를 개시하거나 성혼이 이루어진 경우에는 그로부터 7일 이내에 회사에 그 사실을 통지하여야 합니다. 결혼하기로 확정한 경우에도 같습니다.',
                },
                {
                    id: 6,
                    content:
                        '회원은 회사의 동의가 없는 경우 회사로부터 소개받은 타 회원의 개인정보 및 프로필 사진 등 여하한 개인정보를 어떠한 경우에도 제3자에게 유출하거나 공유하여서는 아니됩니다. 타 회원의 개인정보 공유 및 유출에 따른 회사와 타 회원의 피해에 대해 회사는 민형사상의 모든 법적 조치를 취할 수 있습니다.',
                },
            ],
        },
        {
            title: '제9조 (회원의 인터넷정보서비스 이용시 주의의무)',
            content: [
                {
                    id: 1,
                    content:
                        '회사가 제공하는 인터넷정보서비스 이용시 ID와 비밀번호에 관한 관리책임은 회원에게 있습니다.',
                },
                { id: 2, content: '회원은 자신의 ID 및 비밀번호를 제3자에게 이용하게 해서는 안됩니다.' },
                {
                    id: 3,
                    content:
                        '회원이 자신의 ID 및 비밀번호를 도난당하거나 제3자가 사용하고 있음을 인지한 경우에는 즉시 회사에 통지하고 회사의 안내가 있는 경우에는 그에 따라야 합니다.',
                },
            ],
        },
        {
            title: '제10조 (계약의 종료)',
            content: [
                {
                    id: 1,
                    content: '계약의 종료사유는 다음과 같습니다.',
                    specInfos: [
                        { id: 'a', content: '당사자의 합의에 의한 계약의 해지' },
                        { id: 'b', content: '제6조에서 정한 회원자격 보유기간의 경과' },
                        { id: 'c', content: '회원간의 결혼' },
                        { id: 'd', content: '회원의 사망, 회사의 파산 기타 계약의 목적을 달성할 수 없는 경우' },
                    ],
                },
                {
                    id: 2,
                    content:
                        '회사는 다음 각 호의 사유가 확인된 경우 회원에 대하여 최고하지 아니하고 계약을 해지할 수 있습니다.',
                    specInfos: [
                        {
                            id: 'a',
                            content:
                                '회원이 위조 또는 변조된 서류를 제출한 경우. 다만, 회원의 고의나 중과실이 없는 경우는 제외합니다',
                        },
                        { id: 'b', content: '이성과 동거하고 있는 경우' },
                        { id: 'c', content: '회원이 제8조 제2항, 3항, 4항을 위반하는 경우' },
                        {
                            id: 'd',
                            content:
                                '회원이 제8조 제5항에 위반하여 만남보류 및 취소 신청없이 무단으로 2회 이상 상대방과의 만남에 응하지 아니하거나, 만남보류를 신청하고 제출하는 제8조 제2항의 소명자료가 허위인 경우',
                        },
                        {
                            id: 'e',
                            content:
                                '회원이 회사의 소개로 상대방과 만나거나 교제하면서 사회통념상 상대방에게 모욕감이나 심한 불쾌감을 주는 행위를 하여 상대방이 회사에 그에 관하여 2회 이상 항의한 경우',
                        },
                        { id: 'f', content: '회사가 회원에게 고지한 이용 정지 사유에 해당하는 경우' },
                        {
                            id: 'g',
                            content:
                                '회원이 회사의 임직원에게 경어 사용, 비속어 사용, 인신공격 등 무례한 행위를 한 경우',
                        },
                    ],
                },
                {
                    id: 3,
                    content:
                        '회사는 다음 각 호의 사유가 확인된 경우 회원에 대하여 2주간의 최고를 하고 회원이 재발방지를 위한 적절한 조치를 취하지 아니한 때에는 계약을 해지할 수 있습니다.',
                    specInfos: [
                        { id: 'a', content: '회사의 신용, 명예를 손상시키는 행위를 한 경우' },
                        { id: 'b', content: '회원이 법령 기타 이 약관에 위반되는 행위를 한 경우' },
                    ],
                },
                { id: 4, content: '회원은 언제든지 최고없이 계약을 해지할 수 있습니다.' },
            ],
        },
        {
            title: '제11조 (선불횟수제의 환급)',
            content: [
                {
                    id: 1,
                    content:
                        '선불횟수제 매칭프로그램이란 이성 회원과 상호 만남 의사가 확인되기 전에 매칭프로그램을 구매하여 사용하는 프로그램입니다.',
                },
                {
                    id: 2,
                    content:
                        '회사의 책임 있는 사유로 계약이 해지되는 경우에는 다음과 같이  만남서비스 이용비(이하 가입비)을 환급합니다(회사의 책임있는 사유는 소비자분쟁해결기준에 준함). 가입비는 할인가가 아닌 정가, 부가세를 제외한 공급가를 기준으로 합니다.',
                    specInfos: [
                        {
                            id: 'a',
                            content:
                                '회원가입 계약성립 후 프로필(이성 회원의 정보) 제공 전에 계약이 해지되는 경우: 가입비 + 가입비의 10%',
                        },
                        {
                            id: 'b',
                            content:
                                '회원가입 계약성립 후 프로필(이성 회원의 정보) 제공 후 만남 일자 확정 전에 해지되는 경우: 가입비 + 가입비의 15%',
                        },
                        {
                            id: 'c',
                            content:
                                '회원가입 계약성립 후 회사가 주선한 만남 일자 확정 이후에 해지된 경우: 가입비 + 가입비의 20%',
                        },
                        { id: 'd', content: '1회 만남 이후 해지된 경우: 가입비×(잔여일수/총일수) + 가입비의 20%' },
                    ],
                },
                {
                    id: 3,
                    content: '회사의 책임없이 계약이 해지되는 경우에는 다음과 같이 가입비를 환급합니다.',
                    specInfos: [
                        {
                            id: 'a',
                            content:
                                '회원가입 계약성립 후 프로필(이성 회원의 정보) 제공 전에 계약이 해지되는 경우: 가입비의 90%',
                        },
                        {
                            id: 'b',
                            content:
                                '회원가입 계약성립 후 프로필(이성 회원의 정보) 제공 후 만남 일자 확정 전에 해지되는 경우: 가입비의 85%',
                        },
                        {
                            id: 'c',
                            content:
                                '회원가입 계약성립 후 회사가 주선한 만남 일자 확정 이후에 해지된 경우: 가입비의 80%',
                        },
                        { id: 'd', content: '1회 만남 이후 해지된 경우: 가입비의 80%×(잔여일수/총일수)' },
                    ],
                },
                {
                    id: 4,
                    content:
                        '제10조 제1항 제 3조, 제 10조 제 2항의, 제 10조 제 3항의 사유로 계약이 해지되는 경우에는 가입비를 환급하지 아니합니다.',
                },
                {
                    id: 5,
                    content:
                        '제 10조 1항의 계약 종료 사유에도 불구하고, 회원은 회사의 동의를 얻어 가입비를 환급받지 아니하고 그에 상응하는 회원자격을 타인으로 하여금 보유하게 할 수 있습니다. 다만, 타인은 제3조 제2항에서 정한 적격자에 해당하여야 합니다.',
                },
            ],
        },
        {
            title: '제12조 (후불횟수제의의 환급)',
            content: [
                {
                    id: 1,
                    content:
                        '후불횟수제 매칭 프로그램이란 이성 회원과 상호 만남 의사가 확인 되었을 때 만남권을 구매하여 사용하는 프로그램입니다.',
                },
                {
                    id: 2,
                    content: '만남권은 낱개(1회) 혹은 복수(2회 이상)의 만남권을 묶음 판매합니다.',
                },
                {
                    id: 3,
                    content:
                        '회사의 책임 있는 사유로 만남 진행이 불가능한 경우에는 사용한 만남권을 재지급하여 다시 사용할 수 있도록 합니다. (회사의 책임있는 사유는 소비자분쟁해결기준에 준함)',
                },
                {
                    id: 4,
                    content:
                        '회원이 소개받은 상대방의 귀책으로 만남 진행이 불가능한 경우 사용한 만남권을 재지급하여 다시 사용할 수 있도록 합니다.',
                },
                {
                    id: 5,
                    content:
                        '회원이 희망하여 만남을 진행하지 않는 경우(만남 취소)에는 다음과 같이 만남권을 재지급합니다.',
                    specInfos: [
                        {
                            id: '-',
                            content: '만남 일자 확정 후 ~ 만남 예정일 5일전까지 만남 취소:  만남권 재지급함',
                        },
                        { id: '-', content: '만남 예정일 4일전 ~ 만남 당일까지 만남 취소:  만남권 환급하지 아니함' },
                    ],
                },
                {
                    id: 6,
                    content:
                        '만남권은 결제일로부터 365일 이내에 사용해야합니다. 365일이 초과할 경우 만남권의 효력은 소멸하면 재지급 및 현금 환불이 불가합니다.',
                },
                {
                    id: 7,
                    content:
                        '결제일로부터 365일이 지나지 않고, 사용하지 않은 만남권에 대해 환불을 요청하는 경우 현금 환불합니다.',
                },
                {
                    id: 8,
                    content: '만남권 비용을 현금 환불할 경우 만남권 1회의 정가를 기준으로 환불합니다.<br/> <예시>',
                    specInfos: [
                        {
                            id: '-',
                            content: '매칭 1회권 가격: 11만원 (공급가 10만원, 부가세 1만원)이라고 가정',
                        },
                        { id: '-', content: '매칭 5회권 가격: 44만원 (공급가 40만원, 부가세 4만원)이라고 가정' },
                        { id: '-', content: '매칭 1회권을 구매 후, 사용하지 않고 환불 요청한 경우: 11만원 전액 환불' },
                        {
                            id: '-',
                            content: '매칭 5회권을 구매 후, 1회도 사용하지 않고 환불 요청한 경우: 44만원 전액 환불',
                        },
                        {
                            id: '-',
                            content:
                                '매칭 5회권을 구매 후, 1회를 사용하고 남은 4회에 대해 환불 요청한 경우: 33만원 환불 -> 44만원 - 11만원(1회의 정가)*1회 = 33만원',
                        },
                    ],
                },
                {
                    id: 9,
                    content:
                        '제 12조 제 2항,3항,4항,5항,6항,7항,8항 세부적인 정책은 변경될 수 있으며, 변경될 경우 회원에게 굿퍼슨앱을 통해 고지합니다.',
                },
                {
                    id: 10,
                    content:
                        '제10조 제1항 제 3조, 제 10조 제 2항의, 제 10조 제 3항의 사유로 계약이 해지되는 경우에는 만남권 구매비용을 환불하지 아니합니다.',
                },
                {
                    id: 9,
                    content:
                        '제 10조 1항의 계약 종료 사유에도 불구하고, 회원은 회사의 동의를 얻어 만남권 구매비용을 화불하지 아니하고 그에 상응하는 회원자격을 타인으로 하여금 보유하게 할 수 있습니다. 다만, 타인은 제3조 제2항에서 정한 적격자에 해당하여야 합니다.',
                },
            ],
        },
        {
            title: '제13조 (기간제의 환급)',
            content: [
                {
                    id: 1,
                    content:
                        '기간제 매칭 프로그램이란 정해진 기간 동안 횟수의 제한 없이 만남서비스를 이용하는 매칭 프로그램을 의미합니다. ',
                },
                {
                    id: 2,
                    content:
                        '회사의 책임 있는 사유로 계약이 해지되는 경우에는 다음과 같이 만남서비스 이용비(가입비)를 환급합니다(회사의 책임있는 사유는 소비자분쟁해결기준에 준함). 가입비는 할인가가 아닌 정가, 부가세를 제외한 공급가를 기준으로 합니다.',
                    specInfos: [
                        {
                            id: 'a',
                            content:
                                '회원가입 계약성립 후 프로필(이성 회원의 정보) 제공 전에 계약이 해지되는 경우: 가입비 + 가입비의 10%',
                        },
                        {
                            id: 'b',
                            content:
                                '회원가입 계약성립 후 프로필(이성 회원의 정보) 제공 후 만남 일자 확정 전에 해지되는 경우: 가입비 + 가입비의 15%',
                        },
                        {
                            id: 'c',
                            content:
                                '회원가입 계약성립 후 회사가 주선한 만남 일자 확정 이후에 해지된 경우: 가입비 + 가입비의 20%',
                        },
                        { id: 'd', content: '1회 만남 이후 해지된 경우: 가입비×(잔여일수/총일수) + 가입비의 20%' },
                    ],
                },
                {
                    id: 3,
                    content: '회사의 책임없이 계약이 해지되는 경우에는 다음과 같이 가입비를 환급합니다.',
                    specInfos: [
                        {
                            id: 'a',
                            content:
                                '회원가입 계약성립 후 프로필(이성 회원의 정보) 제공 전에 계약이 해지되는 경우: 가입비의 90%',
                        },
                        {
                            id: 'b',
                            content:
                                '회원가입 계약성립 후 프로필(이성 회원의 정보) 제공 후 만남 일자 확정 전에 해지되는 경우: 가입비의 85%',
                        },
                        {
                            id: 'c',
                            content:
                                '회원가입 계약성립 후 회사가 주선한 만남 일자 확정 이후에 해지된 경우: 가입비의 80%',
                        },
                        { id: 'd', content: '1회 만남 이후 해지된 경우: 가입비의 80%×(잔여일수/총일수)' },
                    ],
                },
                {
                    id: 4,
                    content:
                        '제10조 제1항 제 3조, 제 10조 제 2항의, 제 10조 제 3항의 사유로 계약이 해지되는 경우에는 가입비를 환급하지 아니합니다.',
                },
                {
                    id: 5,
                    content:
                        '제 10조 1항의 계약 종료 사유에도 불구하고, 회원은 회사의 동의를 얻어 가입비를 환급받지 아니하고 그에 상응하는 회원자격을 타인으로 하여금 보유하게 할 수 있습니다. 다만, 타인은 제3조 제2항에서 정한 적격자에 해당하여야 합니다.',
                },
            ],
        },
        {
            title: '제14조 (휴면)',
            content: [
                {
                    id: 1,
                    content:
                        '회원은 가입 기간 중에 회사에 대하여 휴면(홀드)을 요청할 수 있습니다. 휴면 처리가 되면, 회원의 프로필을 타 회원에게 제공하지 않으며, 제5조의 서비스 제공을 일시 정지합니다.',
                },
                {
                    id: 2,
                    content:
                        '휴면 신청 방법, 신청 가능 횟수, 최대 휴면 기간은 매칭 프로그램에 따라 상이하며 자세한 규정은 매니저와 멤버전용앱을 통해 안내합니다.',
                },
                {
                    id: 3,
                    content: '휴면 관련 규정은 변경될 수 있으며 멤버전용앱을 통해 회원에게 안내합니다.',
                },
            ],
        },
        {
            title: '제 15조 (페널티)',
            content: [
                {
                    id: 1,
                    content:
                        '회사는 신뢰가고 진정성 있는 만남 문화를 유지하기 위해 페널티 규정을 정하고, 회원이 페널티 규정을 어긴 경우 페널티를 부과합니다.',
                },
                { id: 2, content: '페널티 규정은 멤버전용앱을 통해 회원에게 안내합니다.' },
            ],
        },
        {
            title: '제 16조 (개인정보의 보호)',
            content: [
                { id: 1, content: '회사는 회원에 관한 정보수집시 필요한 최소한의 결혼관련 개인정보를 수집합니다.' },
                {
                    id: 2,
                    content:
                        '회사가 회원의 개인식별이 가능한 개인정보를 수집하는 때에는 반드시 당해 회원 또는 회원가입신청자의 동의를 받습니다.',
                },
                {
                    id: 3,
                    content:
                        '제공된 개인정보는 당해 회원의 동의없이 목적 외의 이용이나 제3자에게 제공할 수 없으며, 이에 대한 모든 책임은 회사가 집니다. 다만, 다음의 경우에는 예외로 합니다.',
                    specInfos: [
                        {
                            id: 'a',
                            content:
                                '소개 등 회사업무에 필요한 최소한의 회원의 정보(성명, 주소, 전화번호)를 알려주는 경우',
                        },
                        {
                            id: 'b',
                            content:
                                '통계작성, 학술연구,기술개발 또는 시장조사를 위하여 필요한 경우로서 특정 개인을 식별할 수 없는 형태로 제공하는 경우',
                        },
                    ],
                },
                {
                    id: 4,
                    content:
                        '회사가 제2항과 제3항에 의해 회원의 동의를 받아야 하는 경우에는 정보의 수집목적 및 이용목적, 제3자에 대한 정보제공 관련사항(제공받는자, 제공목적 및 제공할 정보의 내용)등을 미리 명시하거나 통지하여야 하며 회원은 언제든지 이 동의를 철회할 수 있습니다.',
                },
                {
                    id: 5,
                    content:
                        '회원은 언제든지 회사가 가지고 있는 자신의 개인정보에 대해 열람 및 오류정정을 요구할 수 있으며 회사는 이에 대해 지체없이 필요한 조치를 취할 의무를 집니다. 이용자가 오류의 정정을 요구한 경우에는 회사는 그 오류를 정정할 때까지 당해 개인정보를 이용하지 않습니다.',
                },
                {
                    id: 6,
                    content:
                        '회사는 개인정보 보호를 위하여 노력하며 회사의 귀책으로 말미암은 개인정보의 분실, 도난, 유출, 변조 등으로 인한 회원의 손해에 대하여 책임을 집니다.',
                },
                {
                    id: 7,
                    content:
                        '회사 또는 그로부터 개인정보를 제공받은 제3자는 계약의 해지 기타 개인정보의 수집목적 또는 제공받은 목적을 달성한 때에는 당해 개인정보를 지체없이 파기합니다.',
                },
            ],
        },
        {
            title: '제17조 (회원에 대한 통지)',
            content: '회원에 대한 통지 및 예고는 서면, 전화, 모사전송 또는 전자우편(e-mail)의 방법으로 할 수 있습니다.',
        },
        {
            title: '제18조 (약관의 해석)',
            content: '이 약관에서 정하지 아니한 사항과 이 약관의 해석은 관계법령 및 상관례에 따릅니다.',
        },
        {
            title: '제19조 (분쟁해결)',
            content: [
                {
                    id: 1,
                    content:
                        '회사는 회원으로부터 제출되는 불만사항 및 의견은 우선적으로 그 사항을 처리합니다. 다만, 신속한 처리가 곤란한 경우에는 회원에게 그 사유와 처리일정을 즉시 통지해 드립니다.',
                },
                {
                    id: 2,
                    content:
                        '이 약관과 관련하여 당사자 사이에 다툼이 있는 경우에는 당사자의 협의로 처리하되 신의성실의 원칙에 따라 원만히 해결합니다.',
                },
            ],
        },
        {
            title: '제20조(재판관할 및 준거법)',
            content: [
                {
                    id: 1,
                    content:
                        '이 약관과 관련하여 회사와 회원간에 발생한 분쟁에 관한 소송은 민사소송법상의 관할법원에 제기합니다.',
                },
                { id: 2, content: '이 약관과 관련하여 회사와 회원간에 제기된 소송에는 한국법을 적용합니다.' },
            ],
        },
    ],
    privacy: {
        abstract:
            '(주)바닐라브릿지(이하 "회사"라 함)가 운영하는 서비스 ‘굿퍼슨‘은 개인정보보호법, 정보통신망 이용촉진 및 정보보호 등에 관한 법률 등 정보통신서비스제공자가 준수하여야 할 관련 법령상의 개인정보보호 규정을 준수하며, 관련 법령에 의거한 개인정보취급방침을 정하여 이용자 권익 보호에 최선을 다하고 있습니다. 회사의 개인정보취급방침은 다음과 같은 내용을 담고 있습니다.',
        privacyIndex: [
            {
                id: 1,
                title: '수집하는 이용자의 개인정보',
                contents: [
                    {
                        id: 'a. 수집하는 개인정보의 항목',
                        content:
                            '이름, 생년월일, 거주지역, 휴대전화 번호, 이메일 주소, 비밀번호, 성별, 키, 체형, 학력, 전공, 직업, 현/전 직장정보,현/전 직업정보, 프로필 사진, MBTI, 연소득 및 보유자산, 결혼 계획, 자기 소개, 운동관련정보,음주관련정보,반려동물관련정보,건강상태,소득,개인자산,가족관계,가족학력,가족직장및직업,프로필사진,기타 회원이 자발적으로 입력하는 프로필 정보 일체<br>서비스 이용과정에서 안정된 서비스 제공을 위해 아래의 정보들이 자동으로 생성되어 수집될 수 있습니다.<br> - IP Address, 쿠키, 방문 일시, 서비스 이용 기록, 불량 이용 기록<br>서비스 이용과정에서 결제를 위해 아래의 정보들이 수집될 수 있습니다. <br> - 카드사명, 카드번호, 통신사, 이동전화번호, 은행명, 계좌번호, 안드로이드 또는 iOS 인앱 결제에 필요한 정보',
                    },
                    {
                        id: 'b. 개인정보 수집방법',
                        content:
                            '회사는 다음과 같은 방법으로 개인정보를 수집하고 있습니다.<br>- 회원가입 및 서비스 이용 과정에서 이용자가 개인정보 수집에 대해 동의를 하고 직접 정보를 입력하는 경우, 해당 개인정보를 수집합니다. <br>- 서비스 이용 및 상담 과정에서 웹페이지, 모바일 웹/앱, 메일, 팩스, 전화,서면 등을 통해 이용자의 개인정보가 수집될 수 있습니다. <br>- 상담 게시판, 이벤트 응모 등을 통해 개인정보가 수집될 수 있습니다.',
                    },
                ],
            },
            {
                id: 2,
                title: '개인정보의 수집 및 이용목적',
                contents: [
                    {
                        id: 'a. 서비스 제공에 관한 계약의 성립ㆍ이행 및 서비스 제공에 따른 요금정산에 활용합니다.',
                        content: '-컨텐츠 제공, 특정 맞춤 서비스 제공, 본인인증, 컨텐츠 구매 및 요금 결제',
                    },
                    {
                        id: 'b. 이용자 관리 및 원활한 서비스 제공을 위해 개인정보를 활용합니다',
                        content:
                            '- 회원제 서비스 이용 신청에 따른 본인확인, 개인식별, 불량이용자의 부정 이용방지와 비인가 사용방지, 서비스 이용 신청 횟수 제한, 분쟁조정을 위한 기록보존, 불만처리 등 민원처리, 고지사항 전달',
                    },
                    {
                        id: 'c. 신규 서비스 개발 및 마케팅, 광고에 활용합니다',
                        content:
                            '- 신규 서비스 개발 및 인증 서비스, 맞춤서비스 제공, 통계학적 특성에 따른 서비스 제공 및 광고 게재, 이벤트 및 광고성 정보 제공 및 참여기회 제공',
                    },
                    {
                        id:
                            'd. 서비스 이용기록과 접속 빈도 분석, 서비스 이용에 대한 통계, 서비스 분석 및 통계에 따른 맞춤 서비스 제공 및 광고 게재 등에 개인정보를 이용합니다.',
                        content: '',
                    },
                ],
            },
            {
                id: 3,
                title: '개인정보의 취급위탁',
                contents: [
                    {
                        id: '',
                        content: '회사는 서비스 향상을 위해서 귀하의 개인정보를 외부에 위탁하여 처리할 수 있습니다.',
                    },
                    {
                        id: 'a. 개인정보의 처리를 위탁하는 경우에는 미리 그 사실을 귀하에게 고지하겠습니다.',
                        content: '',
                    },
                    {
                        id:
                            'b. 개인정보의 처리를 위탁하는 경우에는 위탁계약 등을 통하여 서비스제공자의 개인정보보호 관련 지시엄수, 개인정보에 관한 비밀유지, 제3자 제공의 금지 및 사고시의 책임부담 등을 명확히 규정하고 당해 계약내용을 서면 또는 전자적으로 보관하겠습니다.',
                        ignoreIndent: true,
                        content:
                            '[수탁업체: KCB (주)코리아크레딧뷰로 본인인증] <br>- 위탁 업무 내용: 휴대전화를 통한 가입 시 및 ID찾기 시 본인인증<br>- 개인정보의 보유 및 이용기간: 회원탈퇴시 혹은 위탁계약 종료시까지<br><br>[수탁업체: 나이스 평가정보 주식회사] <br>- 위탁 업무 내용: 휴대전화를 통한 가입 시 및 ID찾기 시 본인인증<br>- 개인정보의 보유 및 이용기간: 회원탈퇴시 혹은 위탁계약 종료시까지<br><br>[수탁업체: Amazon.com , Inc (Amazon Web Service)]<br>- 서버 위치: 대한민국, 서울 리전<br>- 위탁 업무 내용: 안정적인 웹 서버 및 웹 서비스 운영, 계정정보 저장<br>- 개인정보의 보유 및 이용기간: 회원탈퇴시 혹은 위탁계약 종료시까지<br><br>[수탁업체: (주)심플렉스인터넷 CAFE24 SMS서버호스팅] <br>- 위탁 업무 내용: 안정적인 회원관리 목적 등의 SMS 발송을 위한 SMS 호스팅 서비스 운영 <br>- 개인정보의 보유 및 이용기간: 회원탈퇴시 혹은 위탁계약 종료시까지',
                    },
                ],
            },
            {
                id: 4,
                title: '개인정보의 보유 및 이용기간',
                contents: [
                    {
                        id: '',
                        content:
                            '회원 탈퇴, 개인정보의 수집 및 이용목적이 달성된 경우 회원의 개인정보를 지체 없이 파기합니다. 단, 아래 각 사유 및 기간에 한하여 예외적으로 회원의 개인정보를 보유합니다.',
                    },
                    {
                        id: '1. 불량이용자의 재가입 방지, 부정이용 방지, 환불 민원 처리,기타 민원 및 질의응답 대응',
                        content:
                            '-보유기간: 3년<br>-보유정보: 이메일주소 (ID), 휴대전화번호, 실명, 닉네임, 중복가입 또는 부정가입 방지 목적을 위한 식별 사진 정보, 중복가입확인정보, 가입일, 탈퇴일, 해당 민원/질의응답 내용 또는 부정/위반 이용 행위 내용 기록<br><br>결혼회원의 정보 <br>-보유기간: 5년<br>-보유정보: 이메일주소 (ID), 휴대전화번호, 실명',
                    },
                    {
                        id:
                            '2. 회원이 직접 개인정보의 보존을 요청한 경우 또는 회사가 개별적으로 회원의 동의를 얻은 경우',
                        content:
                            '-보유기간 및 보유정보: 회원의 요청 또는 동의를 얻은 항목/기간에 한하여 해당 기간 동안 보유',
                    },
                    { id: '3. 법령에 의거, 이용자의 동의 없이 보존할 것을 정한 경우. ', content: '' },
                    {
                        id: 'a. 계약 또는 청약철회 등에 관한 기록: 5년(전자상거래법 시행령 제6조 제1항 제2호)',
                        content: '',
                    },
                    {
                        id: 'b. 대금결제 및 재화 등의 공급에 관한 기록: 5년(전자상거래법 시행령 제6조 제1항 제3호)',
                        content: '',
                    },
                    {
                        id: 'c. 소비자의 불만 또는 분쟁처리에 관한 기록: 3년(전자상거래법 시행령 제6조 제1항 제4호)',
                        content: '',
                    },
                    { id: 'd. 사이트 방문에 관한 기록: 3개월(통신비밀보호법 시행령 제41조 제2항 제2호)', content: '' },
                ],
            },
            {
                id: 5,
                title: '개인정보 파기절차 및 방법',
                contents: [
                    {
                        id: '',
                        content:
                            '회사는 개인정보 보유기간의 경과 혹은 개인정보의 수집 및 이용목적의 달성 등 개인정보가 불필요하게 되었을 때에는 해당 개인정보를 지체 없이 파기합니다. 회사의 개인정보 파기절차 및 방법은 다음과 같습니다.',
                    },
                    {
                        id: 'a. 파기절차',
                        content:
                            '이용자가 서비스 신청 등을 위해 입력한 정보는 목적이 달성된 후 별도의 DB로 옮겨져(종이의 경우 별도의 잠금장치가 있는 서류보관함) 내부 방침 및 기타 관련 법령에 의한 정보보호 사유에 따라(보유 및 이용기간 참조)일정 기간 저장된 후 파기됩니다.',
                    },
                    {
                        id: 'b. 파기방법',
                        content: '- 종이에 출력된 개인정보는 완전 파쇄하거나 소각하는 방식으로 파기합니다.',
                    },
                ],
            },
            {
                id: 6,
                title: '개인정보의 제3자 제공',
                contents: [
                    {
                        id: '',
                        content:
                            '회사는 개인정보보호법에 따라 이용자의 사전동의가 있거나 법률에 특별한 규정이 있는 경우를 제외하고 개인정보를 제3자에게 제공하지 않습니다.',
                    },
                ],
            },
            {
                id: 7,
                title: '개인정보 취급자의 제한 및 안전성 확보 조치',
                contents: [
                    {
                        id: '',
                        content:
                            '회사는 이용자의 개인정보 취급인원을 담당자에 한정시키고 있고 이를 위한 별도의 비밀번호를 부여하여 정기적으로 갱신하고 있으며, 수시 교육을 통하여 개인정보취급방침의 준수를 강조하고 있습니다.',
                    },
                ],
            },
            {
                id: 8,
                title: '이용자 및 법정대리인의 권리와 그 행사방법',
                contents: [
                    {
                        id: '',
                        content:
                            '이용자 및 법정 대리인은 언제든지 자신의 개인정보를 조회하거나 수정ㆍ삭제할 수 있으며, 자신의 개인정보에 대한 열람을 요청할 수 있습니다. <br><br>이용자는 언제든지 개인정보 처리의 정지를 요청할 수 있으며, 법률에 특별한 규정이 있는 등의 경우에는 처리정지 요청을 거부할 수 있습니다. <br><br>이용자는 언제든지 회원탈퇴 등을 통해 개인정보의 수집 및 이용 동의를 철회할 수 있습니다. <br><br>이용자가 개인정보의 오류에 대한 정정을 요청한 경우, 정정을 완료하기 전까지 해당 개인정보를 이용 또는 제공하지 않습니다. 또한 잘못된 개인정보를 제3자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체 없이 통지하여 정정이 이루어지도록 하겠습니다.<br><br>회사는 이용자 혹은 법정 대리인의 요청에 의해 해지 또는 삭제된 개인정보를 본 개인정보취급방침 에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.',
                    },
                ],
            },
            {
                id: 9,
                title: '개인정보 자동 수집 장치의 설치/운영 및 거부에 관한 사항',
                contents: [
                    {
                        id: '',
                        content:
                            '회사는 이용자들에게 특화된 맞춤서비스를 제공하기 위해서 이용자들의 정보를 저장하고 수시로 불러오는 "쿠키(cookie)"를 사용합니다. 쿠키는 웹사이트를 운영하는데 이용되는 서버(HTTP)가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자들의 PC 컴퓨터내의 하드디스크에 저장되기도 합니다.',
                    },
                    {
                        id: 'a. 쿠키의 사용 목적',
                        content:
                            '이용자들의 편리한 기능을 제공하기 위하여 활용되며 유해한 목적으로는 활용되지 않습니다.',
                    },
                    {
                        id: 'b. 쿠키의 설치/운영 및 거부',
                        content:
                            '이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서 이용자는 웹 브라우저의 옵션을 선택함으로써 모든 쿠키를 허용하거나 쿠키를 저장할 때마다 확인을 거치거나, 모든 쿠키의 저장을 거부할 수 있습니다.<br>- 설정방법 예(인터넷 익스플로어의 경우): 웹 브라우저 상단의 도구 > 인터넷 옵션 > 개인정보',
                    },
                ],
            },
            {
                id: 10,
                title: '개인정보관리책임자 및 담당자의 연락처',
                contents: [
                    {
                        id: '',
                        content:
                            '귀하께서는 회사의 서비스를 이용하시면서 발생하는 모든 개인정보보호 관련 민원을 개인정보 관리담당자 혹은 담당부서에 언제든지 신고하실 수 있습니다.<br>회사는 이용자들의 신고사항에 대해 신속하고 충분한 답변을 드릴 것입니다.<br><br><span class="spoqa-f-bold">개인정보 관리책임자</span><br>이름: 최성만<br>직위: 대표이사<br>전화: 1533-4680<br>메일: support@wouldyoumarry.com<br><br>기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.<br>- 개인정보침해신고센터 (www.118.or.kr / 118)<br>- 정보보호마크인증위원회 (www.eprivacy.or.kr / 02-580-0533~4)<br>- 대검찰청 첨단범죄수사과 (www.spo.go.kr / 02-3480-2000)<br>- 경찰청 사이버테러대응센터 (www.ctrc.go.kr / 02-392-0330)\n',
                    },
                ],
            },
            {
                id: 11,
                title: '고지의 의무',
                contents: [
                    {
                        id: '',
                        content:
                            '현 개인정보취급방침 내용 추가, 삭제 및 수정이 있을 시에는 개정 최소 7일전부터 홈페이지 혹은 모바일 애플리케이션의 ‘공지사항’을 통해 고지됩니다.',
                    },
                    { id: '<br>부칙', content: '본 개인정보취급방침은 2021년 12월 27일부터 적용됩니다.' },
                ],
            },
        ],
    },
    consent: [
        { id: 'a. 수집하고자 하는 민감 정보 항목: 종교', content: '' },
        { id: 'b. 민감정보 수집 및 이용목적: 결혼정보서비스의 제공', content: '' },
        {
            id: 'c. 민감정보 보유 및 이용기간',
            content:
                '- 회사는 민감정보 보유기간의 경과 혹은 민감정보의 수집 및 이용목적의 달성 등 민감정보가 불필요하게 되었을 때에는 해당 민감정보를 지체 없이 파기합니다.<br>- 회사는 회원이 계약을 해지하여 탈퇴하는 경우 민감정보를 지체 없이 파기합니다.',
        },
        {
            id: 'd. 동의 거부 권리 및 불이익 내용',
            content:
                '귀하는 위와 같은 민감 정보 수집 및 이용에 동의하지 않을 수 있습니다. 단, 회원 가입을 위한 최소한의 정보인 필수정보 수집 동의를 거부하시는 경우에는 해당 서비스의 이용 및 정상적인 서비스 제공이 불가능할 수 있음을 알려드립니다.',
        },
    ],
    truth: {
        title:
            '본인은 주식회사 바닐라브릿지가 운영하는 ‘굿퍼슨’ 서비스를 이용함에 있어서 진실된 정보를 제공할 것에 동의한다.',
        content: [
            { id: 1, desc: '본인은 아래 내용이 진실임을 확인한다.', content: '' },
            {
                id: 2,
                desc:
                    '본인은 본 확인서에서 확인한 내용이 진실이 아닌 경우 이로 인해 회사와 상대 이성이 입은 손해의 배상과 별도로 회사의 청구에 의하여 지체 없이 위약벌로 100만원을 회사에 지급해야 한다는 점에 대해서 동의한다. 이를 명확히 하면 상기 위약벌은 회사의 본인에 대한 손해배상청구에 영향을 미치지 않는다.',
                content: [
                    { id: 'a. 교제하고 있는 이성이 없음' },
                    { id: 'b. (초혼 가입자의 경우에만 해당) 사실혼 관계(혼인신고하지 않은 혼인 관계)가 없었음' },
                    { id: 'c. 직업, 직장, 학력 정보가 진실됨' },
                    { id: 'd. 서류 인증이 어려운 정보(소득, 자산 정보)를 의도적으로 크게 과장하거나 왜곡하지 않았음' },
                    { id: 'e. 제출한 인증 서류는 모두 진실됨' },
                ],
            },
            {
                id: 3,
                desc:
                    '단, 가입 이후 새롭게 교제하는 있는 이성이 생기거나 혼인, 사실혼을 하게 될 경우 회사에 즉시 통보하여야 하며, 제 1조 a항, b항의 위약벌 대상에서 제외한다.',
                content: '',
            },
        ],
    },
}
